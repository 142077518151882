<template>
  <div>
    <!--begin::Table-->
    <slot name="header">
      <div class="row">
        <div class="col">
          <slot name="header-left">
            <input
              v-model="paginate.search"
              type="text"
              class="form form-control"
              placeholder="Search..."
            />
          </slot>
        </div>
        <div class="col">
          <slot name="header-center"></slot>
        </div>
        <div class="col-auto">
          <slot name="header-right" v-if="createButton">
            <button
              type="button"
              class="btn btn-primary"
              @click="actionButton('create', null)"
            >
              Create
            </button>
            <!-- <button
              type="button"
              class="btn btn-outline"
              @click="getSelectedRows()"
            >
              Get Selected Rows
            </button> -->
          </slot>
        </div>
      </div>
    </slot>

    <div class="table-responsive">
      <vue3-datatable
        class="mt-10"
        id="kt_customers_table"
        ref="datatable"
        :rows="rows"
        :columns="columns"
        :loading="loading"
        :totalRows="total_rows"
        :pageSize="paginate.pagesize"
        :pageSizeOptions="[10, 25, 50, 100]"
        :search="paginate.search"
        :sortable="true"
        :columnFilter="false"
        :hasCheckbox="true"
        :isServerMode="true"
        :stickyHeader="true"
        paginationInfo=""
        @change="changeServer"
      >
        <template #timestamp="data">
          <div>{{ parseTimeStamp(data) }}</div>
        </template>
        <template #array="data">
          <slot name="array-column" :data="data"></slot>
        </template>
        <template #actions="data">
          <slot name="action-button" :data="data">
            <div class="flex gap-4">
              <button
                type="button"
                class="btn btn-sm btn-success !py-1"
                @click="actionButton('view', data.value)"
              >
                Edit
              </button>
              <button
                type="button"
                class="btn btn-sm btn-danger !py-1"
                @click="actionButton('delete', data.value)"
              >
                Delete
              </button>
            </div>
          </slot>
        </template>
      </vue3-datatable>
    </div>
    <!--end::Table-->
  </div>
</template>
<script>
import { isProxy, toRaw } from "vue";
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import "@/assets/custom_datatable.css";
import Service from "../services/base.service";

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    service: {
      type: String,
      required: true,
    },
    defaultFilter: {
      type: Object,
      required: false,
    },
    createButton: {
      type: Boolean,
      default: true,
    },
    orderDefault: {
      type: String,
      default: "",
    },
  },
  components: {
    Vue3Datatable,
  },
  data() {
    return {
      loading: false,
      rows: [],
      paginate: {
        current_page: 1,
        pagesize: 10,
        column_filters: [],
        search: "",
      },
      total_rows: 10,
    };
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    defaultFilter(value) {
      console.log(value);
    },
  },
  methods: {
    changeServer(data) {
      this.paginate.current_page = data.current_page;
      this.paginate.pagesize = data.pagesize;
      this.fetchData(isProxy(data) ? toRaw(data) : data);
    },
    async fetchData(filters = null) {
      this.loading = true;
      try {
        if (filters) {
          filters.page = filters.current_page - 1;
          filters.size = filters.pagesize;
        }
        filters = { ...filters, ...this.defaultFilter };
        const BaseService = new Service(this.service);
        const { data, meta } = await BaseService.getListData(filters);
        this.rows = data;
        this.total_rows = meta.totalItems;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    actionButton(type, data) {
      let parseData = null;
      if (isProxy(data)) {
        parseData = toRaw(data);
      }
      this.$emit("actionButton", { type, data: parseData });
    },
    setSelectedRows(index) {
      this.$refs.datatable.selectRow(index);
    },
    getRawData() {
      return this.rows;
    },
    getSelectedRows() {
      const selected = this.$refs.datatable.getSelectedRows();
      const selecredParse = selected.map((r) => {
        return isProxy(r) ? toRaw(r) : r;
      });
      this.$emit("selectedRows", selecredParse);
      return selecredParse;
    },
    reload() {
      this.fetchData();
    },
    parseTimeStamp(data) {
      const raw = toRaw(data.value);
      return `${raw.createdAt} / ${raw.updatedAt}`;
    },
  },
};
</script>
